import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  FK_fabrica: z.string({ required_error: REQUIRED_FIELD }),
  produtos: z.array(z.string()).optional(),
  recursos: z.array(z.string()).optional(),
  periodo_inicio: z.date({ required_error: REQUIRED_FIELD }),
  periodo_fim: z.date({ required_error: REQUIRED_FIELD }),
  observacao: z.string().optional(),
});

export type SimulationFormType = z.infer<typeof schema>;
