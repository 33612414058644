import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import { createColumnHelper } from "@tanstack/react-table";
import {
  getIndisponibilityDays,
  getIndisponibilityLine,
  getPeriodDays,
} from "../../utils/date";
import { useSimulationProductionContext } from "../../context";
import TableContainer from "@/components/Container/TableContainer";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import Text from "@/components/Text/Text";
import Row from "@/components/Row";
import { quantityFormater } from "@/utils/number";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/pt-br";
import Empty from "@/components/Empty";
import { cn } from "@/lib/utils";
import { getProductInventory } from "../../utils/simulation";
import {
  getRows,
  isIndisponibilityDemandPlanning,
  isLineIndisponibility,
} from "../../utils/cell";
import PeriodSimulationRow from "../Row/PeriodSimulationRow";
// import { calculateAccumulatedRealProduction } from "../../utils/inventory/realProduction";
import { getResourcesFactoriesProducts } from "../../utils/inventory/line";
import DemandPlanningPopover from "../Popover/DemandPlanningPopover";
import { FindProductInventoryType } from "../../types";
import SimulationItemsCell from "../Cell/Simulation";
import Can from "@/components/Can";
// import DemandPlanningRow from "../Row/DemandPlanningRow";

dayjs.extend(relativeTime);

type SimulationTableType = {
  FK_produto: string;
  descricao: string;
  tipo: string;
  st: string;
  quantidade: number | string;
  quantidade_total: number | string;
  FK_fabrica_recurso?: string | number;
  SK_recurso_fabrica_produto?: string | number;
};

type SeeSimulationProductionTableType = TableData<SimulationTableType>;

type SimulationTableProps = {
  disabled?: boolean;
};

const columnHelper = createColumnHelper<SeeSimulationProductionTableType>();

export default function SimulationTable({
  disabled = false,
}: SimulationTableProps) {
  const [inventory, setInventory] = useState<Array<FindProductInventoryType>>(
    []
  );
  const {
    simulationData,
    lines: { lineValues },
    calculation: { cellValues, getAverage, getTotal, getCount },
    inventory: { handleInventory },
  } = useSimulationProductionContext();
  const {
    previsao_demanda = [],
    simulador_producao,
    venda_acumulada,
    producao_real_acumulada,
  } = simulationData;
  const {
    periodo_fim,
    periodo_inicio,
    indisponibilidade,
    indisponibilidade_linhas,
    produtos_venda_grade = [],
    produtos_producao_real = [],
  } = simulador_producao || {};

  const demandPlanningObject = simulador_producao?.planejamento_demanda?.reduce(
    (acc, item) => {
      const mes = item?.mes.toString().padStart(2, "0");
      const ano = item?.ano;
      const key = `${mes}-${ano}`;
      acc[key] = item;
      return acc;
    },
    {}
  );

  const periodDays = getPeriodDays({
    finalPeriod: periodo_fim,
    initialPeriod: periodo_inicio,
  });
  const indisponibilityDispatchDays = getIndisponibilityDays({
    indisponibility: indisponibilidade,
  });
  const indisponibilityLineDays = getIndisponibilityLine({
    indisponibilityLine: indisponibilidade_linhas,
  });
  const productInventoryObject = getProductInventory(previsao_demanda);
  const resourceFactoryProducts = getResourcesFactoriesProducts({
    demandEstimate: previsao_demanda,
  });

  useEffect(() => {
    const worker = new Worker(
      new URL("../../workers/inventoryWorker.ts", import.meta.url),
      { type: "module" }
    );

    worker.postMessage({
      productInventory: productInventoryObject,
      dates: periodDays,
      indisponibilityDispatchDates: indisponibilityDispatchDays,
      cellLineValues: lineValues,
      gridSale: produtos_venda_grade,
      productRealProduction: produtos_producao_real,
    });

    worker.onmessage = (event) => {
      setInventory(event.data.inventory);
      handleInventory(event.data.inventory);
      worker.terminate();
    };

    return () => {
      worker.terminate();
    };
  }, [simulationData, lineValues]);

  const columns = [
    columnHelper.accessor("FK_produto", {
      header: "ID",
      id: "FK_produto",
      meta: {
        enableColumnOrdering: true,
        row: {
          isGrouped: true,
        },
      },
      size: 140,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição PA",
      id: "descricao",
      meta: {
        enableColumnOrdering: false,
      },
      size: 300,
    }),
    columnHelper.accessor("tipo", {
      header: "Tipo",
      id: "tipo",
      meta: {
        enableColumnOrdering: true,
      },
      size: 110,
    }),
    columnHelper.accessor("st", {
      header: "Status",
      id: "st",
      meta: {
        enableColumnOrdering: true,
      },
      size: 120,
    }),
    columnHelper.accessor("quantidade_total", {
      header: "Total",
      id: "quantidade_total",
      meta: {
        enableColumnOrdering: false,
      },
      cell: ({ row }) => {
        const { quantidade_total } = row.original;
        if (!quantidade_total) return <Empty />;
        return quantityFormater(Number(quantidade_total));
      },
      size: 100,
    }),
    ...periodDays.map((day, index) => {
      const currentDay = dayjs(day).format("YYYY-MM-DD");
      const isIndisponibility = indisponibilityDispatchDays.includes(
        dayjs(day).format("YYYY-MM-DD")
      );
      return columnHelper.display({
        header: () => {
          const demandPlanning =
            demandPlanningObject?.[dayjs(day).format("MM-YYYY")];
          return (
            <Row justify="space-between" className="w-full gap-4">
              <div className="flex flex-col p-0 justify-center align-middle w-full">
                <Text className="font-bold">
                  {dayjs(day).locale("pt-BR").format("DD/MM/YYYY")}
                </Text>
                <Text>{dayjs(day).locale("pt-BR").format("dddd")}</Text>
              </div>
              <DemandPlanningPopover
                description={demandPlanning?.descricao}
                name={demandPlanning?.nome_planejamento}
                type={demandPlanning?.tipo}
                skForecast={demandPlanning?.SK_planejamento_demanda?.toString()}
                isAverage={demandPlanning?.is_undefined}
              />
            </Row>
          );
        },
        meta: {
          enableMenu: false,
          enableColumnOrdering: false,
          cell: {
            customCellShell: ({ shell: { content, ...props }, row }) => {
              const {
                depth,
                original: { st, FK_fabrica_recurso },
              } = row;
              const isTopLevelRow = depth === 0;

              if (isTopLevelRow) {
                return <div {...props}>{content}</div>;
              }

              const indisponibilityDemandPlanning =
                isIndisponibilityDemandPlanning({
                  isIndisponibility: isIndisponibility,
                  status: st,
                });

              const indisponibilityLine = isLineIndisponibility({
                factoryResourceFk: FK_fabrica_recurso,
                currentDay: currentDay,
                indisponibilityLine: indisponibilityLineDays,
              });

              const cellClassName = cn([
                props.className,
                indisponibilityDemandPlanning ? "bg-[#ededed]" : "",
                indisponibilityLine ? "bg-[#ededed]" : "",
              ]);

              return (
                <div {...props} className={cellClassName}>
                  {content}
                </div>
              );
            },
          },
        },
        id: day,
        cell: ({ row }) => {
          const { depth } = row;
          if (depth === 0) return <Empty />;
          const {
            st,
            quantidade = 0,
            FK_fabrica_recurso,
            tipo,
            SK_recurso_fabrica_produto,
            FK_produto,
          } = row.original;

          return (
            <SimulationItemsCell
              index={index}
              currentDay={currentDay}
              fkProduct={FK_produto}
              line={{
                factoryResourceFk: String(FK_fabrica_recurso),
                skResourceFactoryProduct: String(SK_recurso_fabrica_produto),
                quantity: quantidade,
                isIndisponibility: isIndisponibility,
                st,
                tipo,
                disabled,
              }}
              simulation={{
                accumulatedRealProduction: producao_real_acumulada,
                accumulatedSale: venda_acumulada,
                inventory: inventory,
                demandPlanning: previsao_demanda,
                indisponibilityLine: indisponibilityLineDays,
                lineValues,
                productInventoryObject,
                resourceFactoryProductsObject: resourceFactoryProducts,
                productGridSaleObject: produtos_venda_grade,
                productRealProductionObject: produtos_producao_real,
              }}
            />
          );
        },
      });
    }),
  ];

  const dataSubRows = useMemo(
    () =>
      previsao_demanda.map((row) => {
        return {
          FK_produto: row.FK_produto,
          descricao: row.descricao,
          quantidade_total: row.quantidade_total,
          subRows: [
            getRows(row.saldo_inicial),
            getRows(row.venda_grade),
            getRows(row.venda_acumulada),
            getRows(row.venda_media),
            {
              FK_produto: row.FK_produto,
              descricao: row.descricao,
              tipo: row.tipo,
              st: row.st,
              quantidade: row.quantidade,
              quantidade_total: row.quantidade_total,
            },
            ...row.produto_linhas.map((productLine) => ({
              FK_fabrica_recurso: productLine.FK_fabrica_recurso,
              SK_recurso_fabrica_produto:
                productLine.SK_recurso_fabrica_produto,
              FK_produto: productLine.FK_produto,
              descricao: productLine.descricao,
              tipo: productLine.tipo,
              st: productLine.st,
            })),
            getRows(row.producao_real),
            getRows(row.producao_acumulada),
            getRows(row.saldo_final),
          ],
        };
      }),
    [previsao_demanda]
  );

  if (!simulationData.simulador_producao || !inventory) return;

  return (
    <TableContainer className="max-h-[calc(100vh-200px)] h-full">
      <PeriodSimulationRow />
      <Row justify="end">
        <Can
          condition={Object.keys(cellValues).length > 0}
          onTrue={
            <Text className="font-bold text-sm">
              Média: {quantityFormater(getAverage())} Total:{" "}
              {quantityFormater(getTotal())} Contagem: {getCount()}
            </Text>
          }
        />
      </Row>
      <Table<SeeSimulationProductionTableType>
        virtualize={true}
        data={dataSubRows as unknown as SimulationTableType[]}
        columns={columns}
        meta={{
          layout: "stretch",
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        tableState={{
          columnPinning: {
            left: ["FK_produto", "descricao", "tipo", "st", "quantidade_total"],
          },
        }}
      />
    </TableContainer>
  );
}
