import { UseFormReturn } from "react-hook-form";
import { SimulationFormType } from "./zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import { DatePicker } from "@/components/DatePicker";
import FactorySelect from "@/components/Select/FactorySelect";
import ProductSelect from "@/components/Select/ProductsSelect";
import FactoryResourceMultiSelect from "@/components/Select/FactoryResourceMultiSelect";
import { Textarea } from "@/components/ui/textarea";
import Can from "@/components/Can";

type SimulationFormProps = {
  visibleObservation?: boolean;
  form: UseFormReturn<SimulationFormType, any, undefined>;
  onSubmit: (data: SimulationFormType) => void;
  button?: {
    disabled?: boolean;
    title?: string;
  };
};

export default function SimulationForm({
  form,
  button = { title: "Cadastrar" },
  onSubmit,
  visibleObservation = true,
}: SimulationFormProps) {
  const validityStartDate = form.watch("periodo_inicio");
  const factoryId = form.watch("FK_fabrica");
  const factoryResourceId = form.watch("recursos");

  function handleProductsChange(value) {
    const previousValue = form.getValues("produtos") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("produtos", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("produtos", values);
  }

  function handleResourcesChange(value) {
    const previousValue = form.getValues("recursos") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("recursos", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("recursos", values);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name={"FK_fabrica"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Fábrica</FormLabel>
              <FormControl>
                <FactorySelect
                  disabled={true}
                  value={field.value}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row className="gap-2">
          <FormField
            control={form.control}
            name="periodo_inicio"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Período Inicial *</FormLabel>
                <FormControl>
                  <DatePicker {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="periodo_fim"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Período final *</FormLabel>
                <FormControl>
                  <DatePicker {...field} minDate={validityStartDate} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <FormField
          control={form.control}
          name="recursos"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Recursos</FormLabel>
              <FormControl>
                <FactoryResourceMultiSelect
                  fieldToFilter="FK_fabrica"
                  valueToFilter={factoryId}
                  selected={field.value || []}
                  handleSelected={handleResourcesChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="produtos"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Produtos</FormLabel>
              <FormControl>
                <ProductSelect
                  valueToFilter={factoryResourceId}
                  selected={field.value || []}
                  handleSelected={handleProductsChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Can
          condition={visibleObservation}
          onTrue={
            <FormField
              control={form.control}
              name="observacao"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Observação</FormLabel>
                  <FormControl>
                    <Textarea {...field} rows={4} className="resize-none" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          }
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button?.disabled}>
            <Text color="white">{button?.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
