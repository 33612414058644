import { CellLineValuesType } from "@/hooks/useProductionSimulationMutation";
import { createInventoryForDate } from ".";
import { getCellDayLineValue } from "../cell";
import {
  CalculateLineProps,
  ExistsRealProductionProps,
  GetResourceFactoryProductProps,
  GetResourcesFactoriesProductProps,
} from "../../types";

function existsRealProduction({
  onFalse,
  productRealProduction,
}: ExistsRealProductionProps) {
  if (!productRealProduction) return onFalse();
  return Number(productRealProduction.valor);
}

export function calculateCellLineTotal(
  cellLineValue: Array<CellLineValuesType>
) {
  return cellLineValue.reduce(
    (acc, cell) => Number(acc) + Number(cell.valor),
    0
  );
}

export function calculateLine({
  cellLineValues,
  date,
  demand,
  product,
  isUnavailable,
  unavailableInventory,
  currentInventory,
  productRealProduction: { isRealProduction, realProduction },
}: CalculateLineProps) {
  const cellLineValue = getCellDayLineValue({
    cellLineValues,
    date,
    product: product.produto,
  });

  const cellLineTotal = isRealProduction
    ? existsRealProduction({
        onFalse: () => calculateCellLineTotal(cellLineValue),
        productRealProduction: realProduction,
      })
    : calculateCellLineTotal(cellLineValue);

  const remainingInventory = cellLineTotal + currentInventory - demand;

  if (isUnavailable) {
    const inventoryForDate = createInventoryForDate({
      currentInventory,
      remainingInventory: unavailableInventory + cellLineTotal,
      date,
    });
    currentInventory = unavailableInventory + cellLineTotal;
    return { inventoryForDate, inventory: currentInventory };
  }

  const inventoryForDate = createInventoryForDate({
    currentInventory,
    remainingInventory: remainingInventory,
    date,
  });

  currentInventory = remainingInventory;
  return { inventoryForDate, inventory: currentInventory };
}

export function getResourcesFactoriesProducts({
  demandEstimate,
}: GetResourcesFactoriesProductProps) {
  return demandEstimate.flatMap((demand) => {
    return demand.produto_linhas;
  });
}

export function getResourceFactoryProduct({
  resourcesFactoriesProducts,
  skResourceFactoryProduct,
}: GetResourceFactoryProductProps) {
  if (!skResourceFactoryProduct) return;
  return resourcesFactoriesProducts.find(
    (resourceFactoryProduct) =>
      resourceFactoryProduct.SK_recurso_fabrica_produto ==
      skResourceFactoryProduct
  );
}

export function calculateIndisponibilityProduction(
  capacidadeProducaoUnidades: number,
  fatorConversao: number,
  tempoTotalHoras = 24,
  horasIndisponiveis?: number
) {
  if (!horasIndisponiveis) return;
  const producaoTotalUnidades =
    capacidadeProducaoUnidades * (tempoTotalHoras - horasIndisponiveis);

  const producaoTotalCaixas = producaoTotalUnidades / fatorConversao;

  return Math.floor(producaoTotalCaixas);
}
