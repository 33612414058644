const ROUTES = {
  estimativaDemandas: "Estimativa de Demandas",
  inicio: "Inicio",
  alterarSenha: "Alterar Senha",
  produtos: "Produtos",
  simulacoes: "Simulação de Produção",
  fabricas: "Fábricas",
  recursos: "Recursos",
  fabricasRecursos: "Recursos x Fábricas",
  indisponibilidadeRecursos: "Indisponibilidade Programada",
  simuladores: "Simuladores",
  fabricaRecursoProdutos: "Recursos Fábricas x Produtos",
  tipoRecursos: "Tipo de Recursos",
  inventario: "Inventário",
  simuladoresEmbalagem: "Simuladores de Embalagem",
  embalagens: "Embalagens",
  fornecedores: "Fornecedores",
  embalagensFornecedores: "Embalagens x Fornecedores",
  planoProducao: "Plano de Produção",
  planoEmbalagem: "Plano de Ressuprimento de Embalagem",
  modeloCarga: "Modelo de Carga",
  acessoFornecedores: "Gerenciar Acesso de Fornecedores",
  estoqueFornecedores: "Estoque dos Fornecedores",
};

export function getActiveKey() {
  const pathName = window.location.pathname;

  return ROUTES[pathName.replace("/", "")];
}
