export const PRIMARY_COLOR = "#FAA307";
export const TEXT_COLOR = "#909090";
export const AUTH_ROUTES = ["/login", "/recuperarSenha"];
export const MAX_AGE = 1; // 1 dia
export const AUTHENTICATED_ROUTES = ["/inicio"];
export const SHEET_HASH = "AbaVeryHidden";
export const SHEET_INVENTORY_HASH = "AbaVeryHiddenInventario";
export const SHEET_SUPPLIER_STOCK_HASH = "AbaVeryHiddenEstoqueFornecedor";
export const EMPTY_VALUE = 0;
export const SHEET_MODEL_PATH = "./modelo_planilha_padrao.xlsx";
export const REQUIRED_FIELD = "Campo obrigatório";

export const USER_ACCESS = {
  logistics: "logistics",
  operationalCommercial: "operationalCommercial",
  managerCommercial: "managerCommercial",
  guest: "guest",
};
export const CONFIGURATION_TABLES_CODE = {
  dim_tipo_recursos: {
    codigo: 10,
    nome: "dim_tipo_recursos",
  } as const,
};

export const FILIAL_OPTIONS = [
  {
    key: "MG",
    value: "MG",
  },
  {
    key: "RJ",
    value: "RJ",
  },
];
export const STATE_OPTIONS = [
  { key: "AC", value: "AC" },
  { key: "AL", value: "AL" },
  { key: "AP", value: "AP" },
  { key: "AM", value: "AM" },
  { key: "BA", value: "BA" },
  { key: "CE", value: "CE" },
  { key: "DF", value: "DF" },
  { key: "ES", value: "ES" },
  { key: "GO", value: "GO" },
  { key: "MA", value: "MA" },
  { key: "MT", value: "MT" },
  { key: "MS", value: "MS" },
  { key: "MG", value: "MG" },
  { key: "PA", value: "PA" },
  { key: "PB", value: "PB" },
  { key: "PR", value: "PR" },
  { key: "PE", value: "PE" },
  { key: "PI", value: "PI" },
  { key: "RJ", value: "RJ" },
  { key: "RN", value: "RN" },
  { key: "RS", value: "RS" },
  { key: "RO", value: "RO" },
  { key: "RR", value: "RR" },
  { key: "SC", value: "SC" },
  { key: "SP", value: "SP" },
  { key: "SE", value: "SE" },
  { key: "TO", value: "TO" },
];
export const ROUTES = {
  resetPassword: "recuperarSenha",
  initial: "inicio",
  changePassword: "alterarSenha",
  help: "ajuda",
  supplierAccess: {
    index: "acessoFornecedores/",
  },
  supplierStock: {
    index: "estoqueFornecedores/",
  },
  forecast: {
    index: "estimativaDemandas/",
    edit: "editar/:id",
    see: "visualizar/:id",
  },
  productionPlan: {
    index: "planoProducao/",
    edit: "editar/:id",
    see: "visualizar/:id",
    branches: "ramos/:id",
  },
  packagingPlan: {
    index: "planoEmbalagem/",
    edit: "editar/:id",
    see: "visualizar/:id",
  },
  products: {
    index: "produtos/",
  },
  suppliers: {
    index: "fornecedores/",
  },
  packagings: {
    index: "embalagens/",
  },
  packagingSuppliers: {
    index: "embalagensFornecedores/",
  },
  factory: {
    index: "fabricas/",
  },
  resources: {
    index: "recursos/",
  },
  resourcesType: {
    index: "tipoRecursos/",
  },
  simulations: {
    index: "simulacoes/",
  },
  simulators: {
    index: "simuladores/",
    edit: "editar/:id",
    see: "visualizar/:id",
  },
  packagingSimulators: {
    index: "simuladoresEmbalagem/",
    edit: "editar/:id",
    see: "visualizar/:id",
  },
  packagingSimulations: {
    index: "simulacoesEmbalagem/",
  },
  factoryResource: {
    index: "fabricasRecursos/",
  },
  factoryResourceProduct: {
    index: "fabricaRecursoProdutos/",
  },
  resourceIndisponibility: {
    index: "indisponibilidadeRecursos/",
  },
  inventory: {
    index: "inventario/",
    edit: "editar/:id",
  },
  cargoModel: {
    index: "modeloCarga/",
  },
};
