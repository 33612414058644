import { ResponseDataType } from "./../types/index";
import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  productionSimulationsKey,
  ProductionSimulationType,
} from "./useProductionSimulators";
import { AccumulatedSaleType } from "@/pages/Simulacoes/utils/simulation";
import { AccumulatedProductionType } from "@/pages/Simulacoes/types";
import { FactoryColumnsType } from "@/pages/Factory";

export type UseProductionSimulationMutationType = {
  produtos_selecionados?: Array<string>;
  recursos_selecionados?: Array<string>;
  valores_linhas?: string;
  is_visualizacao?: boolean;
  observacao?: string;
} & Pick<
  ProductionSimulationType,
  "FK_fabrica" | "periodo_fim" | "periodo_inicio"
>;

type SimulatorRowsType = {
  FK_produto: string;
  descricao: string;
  tipo: string;
  st: string;
  quantidade: number | string;
  quantidade_total: number | string;
};

export type ProductLineType = {
  SK_recurso_fabrica_produto: string | number;
  FK_fabrica_recurso: string | number;
  capacidade: string;
  FK_produto: string;
  descricao: string;
  tipo: string;
  st: string;
};

export type DemandEstimateResponseType = {
  FK_produto: string;
  descricao: string;
  tipo: string;
  st: string;
  quantidade_total: number | string;
  quantidade: number | string;
  quantidades_por_mes?: {
    [date: string]:
      | {
          quantidade_diaria?: number | string;
          quantidade_total?: number | string;
        }
      | undefined;
  };
  extra: {
    fator_conversao: string;
    volume: string;
  };
  politica_estoque: {
    estoque_maximo: string;
    estoque_minimo: string;
    estoque_reabastecimento: string;
  };
  inventario: {
    FK_produto: string;
    SK_inventario: string;
    filial_origem: number | string;
    quantidade: string | number;
    vigencia: string;
  }[];
  produto_linhas: Array<ProductLineType>;
  saldo_inicial: Array<SimulatorRowsType>;
  venda_grade: Array<SimulatorRowsType>;
  venda_media: Array<SimulatorRowsType>;
  venda_acumulada: Array<SimulatorRowsType>;
  producao_real: Array<SimulatorRowsType>;
  producao_acumulada: Array<SimulatorRowsType>;
  saldo_final: Array<SimulatorRowsType>;
};

export type SimulationResponseType = {
  FK_fabrica: string;
  periodo_inicio: string;
  periodo_fim: string;
  planejamento_demanda?: [
    {
      SK_planejamento_demanda: number;
      descricao: string;
      tipo: string;
      nome_planejamento: string;
      ano: number;
      mes: number;
      is_undefined?: boolean;
    },
  ];
  criado_por: number;
  criado_por_nome: string;
  indisponibilidade?: {
    SK_indisponibilidade_programada: number;
    vigencia_inicio: string;
    vigencia_fim: string;
  }[];
  indisponibilidade_linhas?: {
    SK_indisponibilidade_programada: number;
    FK_fabrica_recurso: string | number;
    vigencia_inicio: string;
    vigencia_fim: string;
    motivo: string;
  }[];
  produtos_selecionados?: Array<string>;
  recursos_selecionados?: Array<string>;
  valores_linhas?: Array<CellLineValuesType>;
  produtos_venda_grade?: {
    FK_produto: string;
    dia: string;
    valor: string;
  }[];
  produtos_producao_real?: Array<ProductRealProductionType>;
};

export type CellLineValuesType = {
  dia: string;
  FK_fabrica_recurso: string | number;
  valor?: string;
  FK_produto: string;
};

export type ProductRealProductionType = {
  FK_produto: string;
  // FK_recurso: number;
  // FK_fabrica_recurso: number;
  valor: string;
  dia: string;
  codigo_linha_producao: string;
};

export type ProductionSimulationResponseType = {
  simulador_producao: SimulationResponseType;
  fabrica: FactoryColumnsType;
  previsao_demanda: Array<DemandEstimateResponseType>;
  venda_acumulada: Array<AccumulatedSaleType>;
  producao_real_acumulada: Array<AccumulatedProductionType>;
  observacao: string;
};

export default function useProductionSimulationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: UseProductionSimulationMutationType) => {
      const response = await instance.put<
        ResponseDataType<ProductionSimulationResponseType>
      >("/planningbeer/productionSimulation", {
        ...data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productionSimulationsKey.all,
      });
    },
  });
}
