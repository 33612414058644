import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreateCargoModelDialog from "./components/Dialog/CreateCargoModelDialog";
import useCargoModels, { CargoModelType } from "./hooks/useCargoModels";
import { createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import Table from "@/components/NewTable";
import ActionCell from "@/components/Cell/ActionCell";
import dayjs from "dayjs";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import { useCargoModelDeleteMutation } from "./hooks/useCargoModelDeleteMutation";
import { validationError } from "@/utils/api";
import { useCargoModelContext } from "./context";
import { toast } from "sonner";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import UpdateCargoModelDialog from "./components/Dialog/UpdateCargoModelDialog";

const columnHelper = createColumnHelper<CargoModelType>();
type CargoModelTable = TableData<CargoModelType>;

export default function CargoModel() {
  const {
    cargoModel: { SK_modelo_carga },
    clear,
    visibleDialog,
    dialogType,
  } = useCargoModelContext();
  const { mutateAsync } = useCargoModelDeleteMutation();
  const { data } = useCargoModels();
  const { data: cargoModel = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_modelo_carga", {
      header: "ID",
      id: "id",
      size: 90,
    }),
    columnHelper.accessor("nome", {
      header: "Nome",
      id: "nome",
      size: 500,
    }),
    columnHelper.accessor("quantidade_palete", {
      header: "Quantidade de Paletes",
      id: "quantidade_palete",
      size: 400,
    }),
    columnHelper.accessor("created_at", {
      header: "Data de Criação",
      id: "created_at",
      size: 400,
      cell: ({
        row: {
          original: { created_at },
        },
      }) => {
        return <span>{dayjs(created_at).format("DD/MM/YYYY")}</span>;
      },
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      size: 100,
      cell: ({ row }) => (
        <ActionCell items={<ActionDropdown {...row.original} />} />
      ),
    }),
  ];

  async function handleDelete() {
    try {
      await mutateAsync(SK_modelo_carga);
      clear();
      return toast.success("Modelo de Carga deletado com sucesso!");
    } catch (err) {
      validationError(err);
    }
  }

  return (
    <AuthenticatedLayout>
      <UpdateCargoModelDialog />
      <DeleteDialogAlert
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir o modelo de carga, "${SK_modelo_carga}"?. Essa ação é irreversível.`}
      />
      <Row justify="end">
        <CreateCargoModelDialog />
      </Row>
      <Table<CargoModelTable>
        columns={columns}
        data={cargoModel}
        meta={{
          layout: "stretch",
        }}
      />
    </AuthenticatedLayout>
  );
}
