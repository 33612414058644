import TableContainer from "@/components/Container/TableContainer";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { ROUTES, STATE_OPTIONS } from "@/constants";
import useEstimate from "@/hooks/useEstimate";
import useEstimatePatchMutation from "@/hooks/useEstimatePatchMutation";
import useEstimateRejectDemandPatchMutation from "@/hooks/useEstimateRejectDemandPatchMutation";
import i18n from "@/i18n";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { useAlertDialogContext } from "../context/AlertDialogContext";
import useBusiness from "@/hooks/useBusiness";
import { quantityFormater } from "@/utils/number";
import { validationError } from "@/utils/api";
import Empty from "@/components/Empty";
import EditEstimateFieldTooltip from "./components/Tooltip";
import EditEstimatePopover from "./components/Popover";
import { getColumnOldLastValue, getColumnOldValues } from "../utils/table";

export interface EstimateDetailType {
  colunas?: string;
  old_valores?: string;
  SK_planejamento_demanda_detalhes: number | string;
  filial_origem: string;
  destino: string;
  quantidade: string;
  FK_produto: number | string;
  descricao: string;
}

export type UpdatedValuesType = {
  id: number | string;
  columnId: string;
  value: string;
};

type EditEstimateTable = TableData<EstimateDetailType>;

const columnHelper = createColumnHelper<EstimateDetailType>();

const PUBLISHED_STATUS = "PUBLICADO";
const TABLE_ORDER_KEY = "editEstimateDemandColumnOrder";

export default function EditEstimate() {
  const { data: businessData } = useBusiness();
  const { data: business } = businessData || {};
  const { actualStatus, handleActualStatus } = useAlertDialogContext();
  const [updatedData, setUpdatedData] = useState<Array<UpdatedValuesType>>([]);

  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useEstimate({ id: Number(id) });
  const { data: estimate = [] } = data || {};
  const [estimateDetailData, setEstimateDetailData] =
    useState<EstimateDetailType[]>(estimate);

  const { mutateAsync, isPending } = useEstimatePatchMutation();
  const { mutateAsync: mutateAsyncRejectDemand } =
    useEstimateRejectDemandPatchMutation();

  const columns = [
    columnHelper.accessor("SK_planejamento_demanda_detalhes", {
      header: "ID",
      id: "SK_planejamento_demanda_detalhes",
    }),
    columnHelper.accessor("FK_produto", {
      header: "Código do Produto",
      id: "FK_produto",
      size: 300,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição",
      id: "descricao",
      size: 600,
    }),
    columnHelper.accessor("quantidade", {
      header: "Quantidade",
      id: "quantidade",
      size: 300,
      meta: {
        cell: {
          formatterFn({ row, value }) {
            const lastValue = getColumnOldLastValue({
              columnName: "quantidade",
              data: estimateDetailData,
              rowId: row.original.SK_planejamento_demanda_detalhes,
            });
            if (!lastValue) return quantityFormater(Number(value));
            return (
              <EditEstimateFieldTooltip
                lastValue={quantityFormater(Number(lastValue))}
                value={quantityFormater(Number(value))}
              />
            );
          },
          customRenderer({ row, value }) {
            const lastValues = getColumnOldValues({
              columnName: "quantidade",
              data: estimateDetailData,
              rowId: row.original.SK_planejamento_demanda_detalhes,
            });
            if (!lastValues) return <Empty />;
            return (
              <EditEstimatePopover
                oldValues={lastValues}
                currentValue={value}
                format={(value) => quantityFormater(Number(value))}
              />
            );
          },
        },
        inputType: "number",
        enableColumnEditable: true,
      },
    }),
    columnHelper.accessor("filial_origem", {
      header: "Origem",
      size: 300,
      id: "filial_origem",
      meta: {
        enableColumnEditable: true,
        inputType: "select",
        selectOptions: business?.map((item) => {
          return { key: item.SK_EMPRESA, value: item.SK_EMPRESA };
        }),
        cell: {
          formatterFn({ row, value }) {
            const lastValue = getColumnOldLastValue({
              columnName: "filial_origem",
              data: estimateDetailData,
              rowId: row.original.SK_planejamento_demanda_detalhes,
            });
            if (!lastValue) return value;
            return (
              <EditEstimateFieldTooltip lastValue={lastValue} value={value} />
            );
          },
          customRenderer({ row, value }) {
            const lastValues = getColumnOldValues({
              columnName: "filial_origem",
              data: estimateDetailData,
              rowId: row.original.SK_planejamento_demanda_detalhes,
            });
            if (!lastValues) return <Empty />;
            return (
              <EditEstimatePopover
                oldValues={lastValues}
                currentValue={value}
              />
            );
          },
        },
      },
    }),
    columnHelper.accessor("destino", {
      header: "Destino",
      id: "destino",
      meta: {
        selectOptions: STATE_OPTIONS,
        inputType: "select",
        enableColumnEditable: true,
        cell: {
          formatterFn({ row, value }) {
            const lastValue = getColumnOldLastValue({
              columnName: "destino",
              data: estimateDetailData,
              rowId: row.original.SK_planejamento_demanda_detalhes,
            });
            if (!lastValue) return value;
            return (
              <EditEstimateFieldTooltip lastValue={lastValue} value={value} />
            );
          },
          customRenderer({ row, value }) {
            const lastValues = getColumnOldValues({
              columnName: "destino",
              data: estimateDetailData,
              rowId: row.original.SK_planejamento_demanda_detalhes,
            });
            if (!lastValues) return <Empty />;
            return (
              <EditEstimatePopover
                currentValue={value}
                oldValues={lastValues}
              />
            );
          },
        },
      },
    }),
  ];

  function handleUpdateRows({ columnId, id, value }: UpdatedValuesType) {
    setEstimateDetailData((prev) => {
      const updatedIndex = prev.findIndex(
        (row) => row.SK_planejamento_demanda_detalhes == id
      );
      if (updatedIndex === -1) {
        return prev;
      }
      const updatedRow = { ...prev[updatedIndex] };
      updatedRow[columnId] = value;

      const newRows = [...prev];
      newRows[updatedIndex] = updatedRow;

      return newRows;
    });
  }

  function handleUpdateData({ columnId, id, value }: UpdatedValuesType) {
    setUpdatedData((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.id === id && item.columnId === columnId
      );
      if (existingIndex !== -1) {
        const updatedData = [...prev];
        updatedData[existingIndex] = { columnId, id, value };
        return updatedData;
      }
      return [...prev, { columnId, id, value }];
    });
  }

  async function handleUpdate() {
    if (updatedData.length == 0) return;
    if (isPending) return;
    try {
      await mutateAsync({ rows: updatedData });
      toast.success(i18n.editSuccess);
      setUpdatedData([]);
      return;
    } catch (error) {
      return validationError(error);
    }
  }

  async function handleUpdateRejectDemand() {
    if (updatedData.length == 0) return;
    if (isPending) return;
    try {
      await mutateAsyncRejectDemand({ id: Number(id), rows: updatedData });
      toast.success(i18n.editSuccess);
      setUpdatedData([]);
      handleActualStatus("");
      return navigate("/" + ROUTES.forecast.index);
    } catch (error) {
      return validationError(error);
    }
  }

  async function handleUpdateFunction() {
    const isPublished = actualStatus == PUBLISHED_STATUS;
    if (isPublished) {
      await handleUpdateRejectDemand();
      return;
    }
    return handleUpdate();
  }

  function getColumnOrder() {
    const demandColumnOrder = localStorage.getItem(TABLE_ORDER_KEY);
    if (demandColumnOrder) {
      return JSON.parse(demandColumnOrder);
    }
    return columns.map((column) => column.id || "");
  }

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  const [columnOrder, setColumnOrder] =
    useState<ColumnOrderState>(getColumnOrder());

  useEffect(() => {
    setEstimateDetailData(estimate);
  }, [estimate]);

  return (
    <AuthenticatedLayout className="px-4 py-0">
      <TableContainer>
        <Table<EditEstimateTable>
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderState={columnOrder}
          columns={columns}
          data={estimateDetailData}
          getRowId={(row) => row.SK_planejamento_demanda_detalhes.toString()}
          meta={{
            layout: "stretch",
            updatedData: updatedData,
            updateData: (id, columnId, value) => {
              handleUpdateRows({ id, columnId, value });
              handleUpdateData({ id, columnId, value });
            },
          }}
        />
      </TableContainer>
      <Row align="end" justify="end" className="w-full mt-4">
        <Button
          variant="ghost"
          disabled={isPending}
          onClick={() => {
            navigate("/" + ROUTES.forecast.index);
          }}
        >
          Voltar
        </Button>
        <Button
          className="ml-4"
          onClick={handleUpdateFunction}
          disabled={isPending}
        >
          Salvar
        </Button>
      </Row>
    </AuthenticatedLayout>
  );
}
