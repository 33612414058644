import Can from "@/components/Can";
import EditLineCellPopover from "../Popover/EditLineCellPopover";
import { useSimulationProductionContext } from "../../context";
import { useRef } from "react";
import { calculateHectoliters, quantityFormater } from "@/utils/number";
import { cn } from "@/lib/utils";
import Col from "@/components/Col";

export type LineCellProps = {
  index: number;
  cellValue: string;
  volume?: string;
  disabled?: boolean;
  currentDay?: string;
  fkFactoryResource?: number;
  fkProduct?: string;
  onChange?: (value?: string) => void;
};

export default function LineCell({
  onChange,
  index,
  currentDay,
  fkProduct,
  fkFactoryResource,
  volume,
  disabled: disabledCell = false,
  cellValue = "0",
}: LineCellProps) {
  const {
    icons: { disabled },
    calculation: { handleCellValues, cellValues },
  } = useSimulationProductionContext();
  const divRef = useRef<HTMLDivElement>(null);
  const cellKey =
    String(fkFactoryResource) + String(currentDay) + String(fkProduct);
  const isSelected = Object.keys(cellValues).includes(cellKey);

  function onValueChange(value?: string) {
    onChange?.(value);
  }

  return (
    <div
      className={cn(
        "w-full h-full flex justify-between items-center",
        isSelected ? "border-gray-600 border" : ""
      )}
      onClick={(e) => {
        if (e.ctrlKey) {
          handleCellValues({
            cellKey,
            value: cellValue,
          });
        }
      }}
    >
      <div className="overflow-x-hidden text-ellipsis" ref={divRef}>
        <Col className="h-full w-full  text-ellipsis justify-center">
          {quantityFormater(Number(cellValue))}
          <span className="bg-gray-300 rounded w-fit px-2 py-1">
            {quantityFormater(calculateHectoliters(cellValue, volume))}
          </span>
        </Col>
      </div>
      <div>
        <Can
          condition={!disabled && !disabledCell}
          onTrue={
            <EditLineCellPopover
              value={cellValue}
              onChange={onValueChange}
              index={index}
            />
          }
        />
      </div>
    </div>
  );
}
