import { DatePicker } from "@/components/DatePicker";
import Row from "@/components/Row";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { SeePackagingPlanFormType } from "./zod";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import dayjs from "dayjs";
import { useEffect } from "react";

export const CURRENT_DAY = dayjs().toDate();
export const FORTY_FIVE_DAYS = dayjs().add(45, "day").toDate();

type SeePackagingPlanFormProps = {
  button?: {
    title: string;
    disabled: boolean;
  };
  form: UseFormReturn<SeePackagingPlanFormType, any, undefined>;
  onSubmit: (data: SeePackagingPlanFormType) => void;
};

export default function SeePackagingPlanForm({
  form,
  button,
  onSubmit,
}: SeePackagingPlanFormProps) {
  const validityStartDate = form.watch("periodo_inicio");

  useEffect(() => {
    form.reset({
      periodo_fim: FORTY_FIVE_DAYS,
      periodo_inicio: CURRENT_DAY,
    });
  }, []);

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Row className="gap-2">
          <FormField
            control={form.control}
            name="periodo_inicio"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Período Inicial *</FormLabel>
                <FormControl>
                  <DatePicker {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="periodo_fim"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Período final *</FormLabel>
                <FormControl>
                  <DatePicker {...field} minDate={validityStartDate} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button?.disabled}>
            <Text color="white">{button?.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
